<template>
	<div>
		<div class="row p-l-15 p-b-10">
			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-5">
					<span>{{$t('설비 유형명')}}</span>
				</div>
				<div class="p-l-5">
					<valid-input
						ref="keyword"
						:classList="'form-control'"
						@changeMethod="onSearch"
						:inputType="'text'"
						:inputValue.sync="systemCodeName"
						:rules="rules.EQUIP_SYSTEM_TYPE_SEARCH_NAME_RULE"
						:NonStatus="true"
					></valid-input>
				</div>
			</div>
			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-15">
					<span>{{$t('사용여부')}}</span>
				</div>
				<div class="p-l-5">
					<select class="form-control" ref="enabled" @change="onChangeCombo">
						<option value="">{{$t('전체')}}</option>
						<option value="Y">{{$t('사용')}}</option>
						<option value="N">{{$t('사용안함')}}</option>
					</select>
				</div>
			</div>
			<div class="font-weight-bold d-flex flex-row p-l-5" :style="{ fontSize: '15px' }">
				<button class="form-control" @click="onSearch">{{$t('조회')}}</button>
				<button class="form-control ml-1 mr-1" @click="onNew">{{$t('신규')}}</button>
				<!-- <button class="form-control" @click="onRemove">삭제</button> -->
			</div>
		</div>
	</div>
</template>

<script>
	import backEndApi from "../../../api/backEndApi";
	import * as rules from "@src/consts/ruleConsts.js";

	export default {
		props: [],
		components: {},
		watch: {},
		data() {
			return {
				systemCodeList: null,
				systemCodeName: "",
				rules
			};
		},
		created() {
			// 처음 진입 시에만 호출
			this.init();
		},
		mounted() {},
		methods: {
			init() {
				// 대분류코드 목록
				this.getEquipTypeList();
			},
			getEquipTypeList() {
				backEndApi.equipType.getEquipTypeList().then(({ data }) => {
					this.systemCodeList = data;
					this.onSearch();
				});
			},
			// Event
			onChangeCombo() {
				this.onSearch();
			},
			onSearch() {
				console.log(this.$refs);
				var keyword = this.$refs.keyword.value; // 대분류코드
				var enabled = this.$refs.enabled.value; // 사용여부
				console.log(this.systemCodeList);
				var result = this.systemCodeList
					.filter((data) =>
						keyword == ""
							? data.equipTypeCodeName == data.equipTypeCodeName
							: data.equipTypeCodeName.includes(keyword) || keyword == ""
							? data.equipTypeCode == data.equipTypeCode
							: data.equipTypeCode.includes(keyword)
					)
					.filter((data) => (enabled == "" ? data.enabled == data.enabled : data.enabled == enabled));

				this.$emit("search:data", result);
			},
			reSearch() {
				this.systemCodeList = null;

				this.getEquipTypeList();
			},
			onNew() {
				this.$emit("new");
			},
		},
	};
</script>

<style scoped></style>
