<template>
	<b-table
		hover
		bordered
		head-variant="dark"
		no-border-collapse
		show-empty
		sticky-header
		ref="selectableTable"
		:class="'text-center'"
		:items="items"
		:fields="fields"
		:empty-text="$t('조회된 결과가 없습니다.')"
		:select-mode="'single'"
		selectable
		:details-td-class="'rowDetailStyle'"
		@row-selected="onRowSelected"
		@row-clicked="onRowDetail"
	>
		<template #empty="scope">
			<h4>{{ scope.emptyText }}</h4>
		</template>

		<template #cell(selected)="{ rowSelected }">
			<template v-if="rowSelected">
				<span>&check;</span>
				<span class="sr-only">Selected</span>
			</template>
			<template v-else>
				<span>&nbsp;</span>
				<span class="sr-only">Not selected</span>
			</template>
		</template>

		<template #cell(index)="data">
			{{ data.index + 1 }}
		</template>

		<template #row-details="row" :class="'bg-white'">
			<vvo v-slot="v">
				<b-card>
					<div>
						<detail-btn @update="onValidate(v, onModify, selectedRow)" @delete="onRemove" @reset="onReset" />
					</div>
					<!-- <div class="text-left font-weight-bold p-5 d-flex" style="font-size: 12px;">
          <i class="material-icons mr-1">info</i><span class="h4 mb-0">관제점 정보</span>
        </div> -->
					<detail-box :ref="'detail'" :equipTypeCode="row.item.equipTypeCode" @reSearch="reSearch" :isUpdate="true" />
				</b-card>
			</vvo>
		</template>
	</b-table>
</template>

<style scoped>
	.b-table-sticky-header {
		max-height: 760px;
	}
</style>

<script>
	// import detailCard from "./GridCard.vue";
	import detailBox from "../../../../system-type/equip-type/EquipTypeDetail.vue";
	import detailBtn from "../../../../system-type/equip-type/EquipTypeDetailBtn.vue";

	export default {
		components: {
			detailBox,
			detailBtn,
			// detailCard,,detailBtn
		},
		props: ["data"],
		data() {
			return {
				fields: null,
				items: null,
				emptyText: null,
				selectedRow: [],
			};
		},
		watch: {
			data() {
				this.selectedRow._showDetails = !this.selectedRow._showDetails;
				this.setItems();
			},
		},
		created() {
			this.setFields();
			this.setItems();
		},
		mounted() {},
		methods: {
			onRowSelected(item) {
				if (!item.length > 0) {
					this.selectedRow._showDetails = false;
					this.selectedRow = [];
				}
			},
			onRowDetail(row) {
				// 이전 선택된 row close
				this.selectedRow._showDetails = !this.selectedRow._showDetails;

				// 선택된 row open
				row._showDetails = !row._showDetails;

				// 선택된 row 저장
				this.selectedRow = row;
			},
			clearSeclected() {
				this.$refs.selectableTable.clearSelected();
			},
			setFields() {
				this.fields = [
					{ key: "index", label: global.xe.$t("순번") },
					{ key: "equipTypeCodeName", label: global.xe.$t("설비 유형명"), tdClass: "text-left" },
					{ key: "equipTypemDetailDsc", label: global.xe.$t("설비 유형 설명"), tdClass: "text-left" },
					{ key: "enabled", label: global.xe.$t("사용여부") },
				];
			},
			setItems() {
				this.items = this.data;
			},
			onModify() {
				this.$refs.detail.onModify();
			},
			onRemove() {
				this.$refs.detail.onRemove();
			},
			onReset() {
				this.$refs.detail.onReset();
			},
			reSearch() {
				this.$emit("reSearch");
			},
		},
	};
</script>
