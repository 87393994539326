<template>
  <div class="col-md-12 default">
    <equip-type />
  </div>
</template>

<script>
import equipType from "./EquipType.vue";

export default {
  components: {
   equipType
  },
  props: ["type"],
  data() {
    return {
      
    };
  },
  computed: {},
  created() {
  },
  mounted() {
  },

  methods: {
   
  },
};
</script>
